import React, { useState } from "react";
import fImg from "../../images/faq/faq-right-bg.png";

import { useLanguage } from "../LanguageContext";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const FaqSection = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const { state } = useLanguage();
  const { language } = state;

  return (
    <section className="faq-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 pr-125 rpr-15">
            <div className="faq-left-image"></div>
          </div>
          <div className="col-lg-6 pl-0">
            <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
              <div className="section-title mb-35">
                <h6>
                  {language === "ar" ? "في ميرا جيرمني للمقاولات" : "Best Architecture"}
                </h6>
                <h2>
                  {language === "ar"
                    ? "نحن حكاية تتشكل من التصاميم الفريدة "
                    : "Architecture did expect"}
                  <span className="thin">
                    {language === "ar" ? " والتنفيذ الدقيق" : "strategist"}
                  </span>
                </h2>
              </div>
              <div id="accordion">
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="1" className="card-header">
                      {language === "ar" ? "خدماتنا" : "Our Services"}
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      {language === "ar" ? (
                        <>
                          <p>
                          مع ميرا جيرمني للمقاولات، تأتي الخدمات بلمسة فنية واهتمام دقيق. نحن نقدم أكثر من مجرد بناء  نقدم تجربة فريدة من نوعها. خبرتنا تتجاوز التنفيذ العادي، حيث يتم تصميم كل مشروع باستمرارية الجمال والابتكار. نحن هنا لتحويل أحلامك إلى واقع، بجودة لا تضاهى والتزام لا يتلاشى. اختر  لتجربة مقاولات تتجاوز التوقعات وتجسد الفخامة في كل تفصيل
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                          With Mira Germany Contracting, services come with an artistic touch and meticulous attention. We offer more than just construction - we provide a unique experience. Our expertise goes beyond ordinary execution, where each project is designed with a continuous blend of beauty and innovation. We are here to transform your dreams into reality, with unparalleled quality and enduring commitment. Choose Mira Germany for a contracting experience that surpasses expectations and embodies luxury in every detail
                          </p>
                        </>
                      )}
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="2" className="card-header">
                      {language === "ar" ? "مهمتنا" : "Mission"}
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      {language === "ar" ? (
                        <>
                          <p>
                            قد تكون مهمة شركة ميرا للمقاولات هي تقديم حلول بناء
                            مبتكرة ومستدامة وذات جودة عالية تلبي الاحتياجات
                            المتطورة للعملاء والمجتمعات. قد تركز الشركة على
                            إنشاء مساحات تعزز نوعية الحياة مع مراعاة الآثار
البيئية                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            The mission of Mira Building Company could be to
                            provide innovative, sustainable, and high-quality
                            construction solutions that meet the evolving needs
                            of clients and communities. The company might focus
                            on creating spaces that enhance the quality of life
                            while being mindful of environmental impacts.
                          </p>
                        </>
                      )}
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="3" className="card-header">
                      {language === "ar" ? "القيم" : "Values"}
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      {language === "ar" ? (
                        <>
                          <p>
                            تشمل القيم الأساسية النزاهة والابتكار والاستدامة
                            ورضا العملاء والسلامة. قد تولي شركة ميرا جيرمني للمقاولات
                            أولوية لممارسات البناء الصديقة للبيئة، بهدف تقليل
                            الآثار البيئية لمشاريعها
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Core values could include integrity, innovation,
                            sustainability, customer satisfaction, and safety.
                            Mira Building Company might prioritize eco-friendly
                            construction practices, aiming to reduce the
                            environmental footprint of their projects.
                          </p>
                        </>
                      )}
                    </AccordionBody>
                  </AccordionItem>
                  {/* <AccordionItem className="card">
                    <AccordionHeader targetId="4" className="card-header">
                      {language === "ar" ? "المشاريع" : "Projects"}
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      {language === "ar" ? (
                        <>
                          <p>
                            يمكن أن تتراوح مشاريعهم من المباني الرمزية في المدن
                            الكبرى إلى الهياكل الوظيفية والجمالية في المناطق
                            الضواحي والريفية. قد تكون للشركة سمعة بتسليم
                            المشاريع في الوقت المحدد وDentro del presupuesto، مع
                            التركيز على حرفية الجودة.
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Their projects could range from landmark buildings
                            in major cities to functional and aesthetic
                            structures in suburban and rural areas. The company
                            might have a reputation for delivering projects on
                            time and within budget, with a focus on quality
                            craftsmanship.
                          </p>
                        </>
                      )}
                    </AccordionBody>
                  </AccordionItem> */}
                </Accordion>
              </div>
            </div>
            <div className="faq-right-bg">
              <img src={fImg} alt="FAQ" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
