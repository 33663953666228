import React from 'react'
import { Link } from 'react-router-dom'
import { useLanguage } from '../LanguageContext';

const ProjectSection = (props) => {
    const { state } = useLanguage();
    const { language } = state;

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const Projects = [
        {
            Id: '1',
            pTitle: language === 'ar' ? 'الهندسة المعمارية' : 'Architecture',
            slug: 'Architecture',
            pImg: '2.3.jpg',
            pSImg: 'https://res.cloudinary.com/dcxnmagjx/image/upload/v1707498476/property-right_llk11g.png',
            ssIcon: '1.5.png',
            des: language === 'ar' ? 'خدمات هندسية معمارية متقدمة من ميرا جيرمني تحقق الجمال والابتكار في كل تصميم. افتح أبواب أحلامك، حيث الهندسة تلتقي بالتميز' : 'Advanced architectural engineering services from Mira Germany achieve beauty and innovation in every design. Open the doors to your dreams, where engineering meets excellence',
            width: '120',
        },
        {
            Id: '2',
            pTitle: language === 'ar' ? 'التصميم الخارجي ' : 'Exterior Design',
            slug: 'Exterior-Design',
            pImg: '2.2.jpg',
            pSImg: 'https://res.cloudinary.com/dcxnmagjx/image/upload/v1707498464/property-left-content-bg_ks3tfj.png',
            ssIcon: '1.4.png',
            des: language === 'ar' ? 'تصميم خارجي يفوق التوقعات. اجعل عالمك يتحول للوحة فنية بتوقيع  الجمال في كل تفصيل، لأنك تستحق الأفضل' : 'Exterior design beyond expectations. Transform your world into an artistic masterpiece with the signature touch of beauty in every detail, because you deserve the best',
            width: '130',
        },
        {
            Id: '3',
            pTitle: language === 'ar' ? 'التصميم الداخلي' : 'Interior',
            slug: 'Interior',
            pImg: '2.1.jpg',
            pSImg: 'https://res.cloudinary.com/dcxnmagjx/image/upload/v1707498476/property-right_llk11g.png',
            ssIcon: 'https://res.cloudinary.com/dcpte972l/image/upload/v1707722418/1.2_v8g7nt.png',
            des: language === 'ar' ? 'اجعل من منزلك تحفة فنية. خدمة التصميم الداخلي تضفي الأناقة والفخامة إلى كل زاوية. تميز بأفضل التصاميم، لأنك تستحق الراحة والجمال' : 'Turn your home into a masterpiece. Interior design service adds elegance and luxury to every corner. Distinguish yourself with the finest designs because you deserve comfort and beauty',
            width: '140',
        },
    ];

    return (
        <section className="apartment-section text-center mb-155">
            <div className="container">
                <div className="section-title mb-75">
                <h2>{language === 'ar' ?  '  خدماتنا   '  : '"Versatile " '}<span className="thin">{language === 'ar' ? ' المتنوعة  '   : 'Services'}</span></h2>
                    <p>{language === 'ar' ? 'نحن هنا لتلبية احتياجاتك بكفاءة وتنوع. خدماتنا المتنوعة تشمل كل ما تحتاجه لبناء عالمك الخاص' : 'We are here to meet your needs efficiently and diversely. Our versatile services encompass everything you need to build your own world '}</p>
                </div>
                <div className="apartment-carousel">
                    <div className="row">
                    {Projects.slice(0, 3).map((project, i) => (
                        <div className="col col-lg-4 col-md-6 col-12" key={i}>
                            <div className="apartment-item">
                                <div className="apartment-img">
                                    <img src={project.pImg} alt="Apartment" />
                                </div>
                                <div className="apartment-content">
                                    <img src={project.ssIcon} alt="Icon" />
                                    <h3><Link >{project.pTitle}</Link></h3>
                                    {/** Removed : onClick={ClickHandler} to={`/project-single/${project.slug}`} */}
                                    {/* <span>{language === 'ar' ? `من ${project.width} متر مربع` : `from ${project.width} m2`}</span> */}
                                    <p>{project.des} </p>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectSection;
