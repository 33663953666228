import React from 'react';
import Bg from '../../images/services/service-bg.jpg';
import { useLanguage } from '../LanguageContext'; // Import useLanguage from your context

const servicesData = [
  {
    Id: "1",
    sImg: "https://res.cloudinary.com/dcpte972l/image/upload/v1707722418/1.4_mogazy.png",
    sTitle: {
      en: "Superior Quality",
      ar: "الجودة الفائقة",
    },
    description: {
      en: "At Mira Germany, we are committed to delivering the highest levels of quality in all stages of our work, from design to execution",
      ar: "نحن في ميرا جيرمني نلتزم بتقديم أعلى مستويات الجودة في جميع مراحل عملنا، من التصميم إلى التنفيذ "   },
  },
  {
    Id: "2",
    sImg: "https://res.cloudinary.com/dcpte972l/image/upload/v1707722418/1.5_nczllp.png",
    sTitle: {
      en: "Creativity and Excellence in Design",
      ar: "الإبداع والتميز في التصميم ",
    },
    description: {
      en: "We take pride in innovation and excellence in designing spaces and environments to create unique and exciting experiences",
      ar: "نفتخر بالابتكار والتميز في تصميم المساحات والبيئات لنخلق تجارب فريدة ومثيرة "   },
  },
  {
    Id: "3",
    sImg: "https://res.cloudinary.com/dcpte972l/image/upload/v1707722418/1.3_qhvbvf.png",
    sTitle: {
      en: "Commitment to Deadlines",
      ar: "الالتزام بالمواعيد",
    },
    description: {
      en: "We are committed to delivering our projects on time, ensuring customer satisfaction and project success",
      ar: "نحن نلتزم بتقديم مشاريعنا في الوقت المحدد، مما يضمن رضا العملاء ونجاح المشاريع ",
    },
  },
];

const ServiceSection = ({ ptClass = '' }) => {
    const { state } = useLanguage();
    const { language } = state;

    return (
        <section className={`services-section rpt-100 pb-70 ${ptClass}`} style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row">
                    {servicesData.map((service) => (
                        <div className="col-lg-4 col-md-6 col-12" key={service.Id}>
                            <div className="service-item">
                                <span className="number">0{service.Id}</span>
                                <img src={service.sImg} alt="Service icon" />
                                <h3>{service.sTitle[language]}</h3>
                                <p>{service.description[language]}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;
