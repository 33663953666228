import React from 'react'
import { useLanguage } from '../LanguageContext';

const CtaSection = (props) => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }
    const { state } = useLanguage();
	const { language } = state;
    return (
        <section className="call-action">
        <div className="container">
            <div className="call-action-inner">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="section-title text-white rmb-35">
                            <h6>{language === 'ar' ? 'لمعلومات اكثر' : 'get quote'}</h6>
                            <h2>{language === 'ar' ? 'تواصل معنا' : 'call for action'}</h2>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <form onSubmit={SubmitHandler} className="subscribe">
                            <input type="email"  placeholder={language === 'ar' ? 'أدخل بريدك الإلكتروني' : 'enter your mail'} required />
                            <button type="submit" className="theme-btn style-two">{language === 'ar' ? 'تواصل معنا' : 'subscribe'}</button>
                        </form> 
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default CtaSection;