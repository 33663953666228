import AnchorLink from "react-anchor-link-smooth-scroll";
import React, { useState } from "react";
import LanguageSelector from "../LanguageSelector";
import { useLanguage } from "../LanguageContext";

const Hero = () => {
  const cloudinaryImageUrl =
    "https://res.cloudinary.com/dcpte972l/image/upload/v1705484288/window-business-building-architecture-view_qzhevl.jpg";

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // 50% opacity black background
  };
  const { state } = useLanguage();
  const { language } = state;
  const [menuActive, setMenuState] = useState(false);
  const [SearchActive, setSearchState] = useState(false);

  return (
    <section
      className="hero-section hero-image text-center mt-110 rmt-70 position-relative"
      style={{ backgroundImage: `url(${cloudinaryImageUrl})` }}
    >
      <div style={overlayStyle}></div> {/* Overlay div */}
      <div className="container position-relative">
        <div className="hero-content">
          <h1 className="text-bold text-white">
            <span>
              {language === "ar" ? "ابني منزل" : "Build your "}
            </span>
          </h1>
          <h1 className="text-lighter">
            {" "}
            <span>
              {language === "ar" ? "احلامك " : "dream house"}
            </span>
          </h1>
          <AnchorLink href="#about" className="scroll-down scroll">
            <div className="scroll-box"></div>
          </AnchorLink>
        </div>
      </div>
    </section>
  );
};

export default Hero;
