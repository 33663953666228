import React from "react";
import { useLanguage } from "../LanguageContext";



const services = [
  {
    id: 1,
    title: "Home Maintenance Services",
    title1:'خدمات صيانة المنازل',
    description: "For apartments, villas, and offices.",
    description1:"خدمات الصيانة للشقق ، الفلل و المكاتب",
    image:
      "https://res.cloudinary.com/dcpte972l/image/upload/v1706779620/Home_maintenance_services_for_apartments_villas_and_offices_bx5w6u.jpg",
  },
  {
    id: 2,
    title: "Maintenance and Installation of Air Conditioners",
    title1:'صيانة وتركيب مكيفات الهواء',
    image:
      "https://res.cloudinary.com/dcpte972l/image/upload/v1706779615/Screenshot_2024-02-01_131247_hwcauh.png",
  },
  {
    id: 3,
    title: "Painting and Plastering Works",
    title1:'أعمال الدهان والتشطيب',
    image:
      "https://res.cloudinary.com/dcpte972l/image/upload/v1706779617/Screenshot_2024-02-01_131315_zm9hvv.png",
  },
  {
    id: 4,
    title: "Installing Aluminium Doors",
    title1:'تركيب أبواب الألمنيوم',
    image:
      "https://res.cloudinary.com/dcpte972l/image/upload/v1706779611/Screenshot_2024-02-01_131222_d7aqta.png",
  },
  {
    id: 5,
    title: "Ceramic Installation and Replacement",
    title1:'تركيب واستبدال السيراميك',
    image:
      "https://res.cloudinary.com/dcpte972l/image/upload/v1706779602/Screenshot_2024-02-01_131030_ya8x5j.png",
  },
  {
    id: 6,
    title: "Bathroom Services",
    title1:'خدمات صيانة الحمامات',
    description1:'استبدال وتركيب الحمامات، تركيب الغسالات، استبدال جهاز تنقية المياه',
    description:
      "Replacement and installation of bathrooms, Laundry installation, Water softener replacement.",
    image:
      "https://res.cloudinary.com/dcpte972l/image/upload/v1706779620/Screenshot_2024-02-01_131405_v9dyqw.png",
  },
];

const Services = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault()
}
  const { state } = useLanguage();

  const { language } = state;
  return (
    <div className="container py-5">
      <h2 className="text-center mb-4" >{language == 'ar' ? 'خدماتنا'  :'Our Services'}</h2>
      <div className="row g-4">
        {services.map((service) => (
          <div className="col-md-4" key={service.id}>
            <div className="card h-100">
              <img
                src={service.image}
                className="card-img-top"
                alt={service.title}
                style={{ objectFit: "cover", height: "200px" }}
              />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title" style={{fontWeight: 'bold'}}>{language == 'ar' ? service.title1 : service.title}</h5>
                <p className="card-text flex-grow-1">{language == 'ar' ? service.description1 : service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

{/*arabic added in this page* */}

export default Services;
