import React from 'react';
import AllRoute from '../router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LanguageProvider } from '../../components/LanguageContext';
import '../../css/style.css';
const App = () => {
	return (
		<div className='App' id='scrool'>
			<LanguageProvider>
				<AllRoute />
				<ToastContainer />
			</LanguageProvider>
		</div>
	);
};

export default App;
