// LanguageContext.js
import React, { createContext, useReducer, useContext } from 'react';

// Define initial state
const initialState = {
	language: 'en', // Default language is English
};

// Create context
const LanguageContext = createContext();

// Define reducer function
const languageReducer = (state, action) => {
	switch (action.type) {
		case 'SET_LANGUAGE':
			return { ...state, language: action.payload };
		default:
			return state;
	}
};

// Create a custom provider for your context
export const LanguageProvider = ({ children }) => {
	const [state, dispatch] = useReducer(languageReducer, initialState);

	return (
		<LanguageContext.Provider value={{ state, dispatch }}>
			{children}
		</LanguageContext.Provider>
	);
};

// Custom hook to consume the context
export const useLanguage = () => {
	const context = useContext(LanguageContext);
	if (!context) {
		throw new Error('useLanguage must be used within a LanguageProvider');
	}
	return context;
};
