

const Services = [
  {
    Id: "1",
    sImg: "https://res.cloudinary.com/dcxnmagjx/image/upload/v1707561476/icon3_kioopk.png",
  
    sTitle: "Design & Planning",
    slug: "Design-&-Planning",
    description:
      "Design and planning are the cornerstone of successful projects, where creativity meets strategic foresight to bring ideas to life",
    

  },
  {
    Id: "2",
    sImg: "https://res.cloudinary.com/dcxnmagjx/image/upload/v1707561476/icon2_nhpq06.png",
    
    sTitle: "Custom Solutions",
    slug: "Custom-Solutions",
    description:
      "Custom solutions cater to unique needs, offering personalized and effective answers tailored to specific challenges",
    

  },
  {
    Id: "3",
    sImg: "https://res.cloudinary.com/dcxnmagjx/image/upload/v1707561476/icon1_kbfyfe.png",
    
    sTitle: "Furniture & Decor",
    slug: "Furniture-&-Decor",
    description:
      "Furniture and decor seamlessly blend functionality with aesthetics, transforming spaces into inviting and stylish environments.",
    

  },
  
];
export default Services;
