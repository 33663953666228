import React from 'react'
import { Link } from 'react-router-dom'
import { useLanguage } from '../LanguageContext';

const PageTitle = () => {
    const { state } = useLanguage();
    const { language } = state;
    return (
        <section className="banner-section mt-110 rmt-70">
            <div className="container">
                <div className="banner-inner">
                    <div className="page-title">
                        <h2 style={{ paddingBottom: 30 }}  > {language === 'ar' ? 'ميرا جيرمني' : 'Mira Germany'}</h2>


                        <h3 style={{ color: '#2a2e85', fontWeight: 'bold', fontSize: 20, backgroundColor: 'white'  }}>{language === 'ar' ? 'نتفرد في كل تفصيلة، تفوق في كل مشروع' : 'We excel in every detail, surpass in every project'}</h3>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">{language === 'ar' ? 'الرئيسية' : 'Home'}</Link></li>

                        </ol>
                    </nav>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;