import React, { useState } from 'react';
import Bg from '../../images/wp/wp-bg.png'
import abimg1 from '../../images/wp/wp-1.png'
import abimg2 from '../../images/wp/wp-2.png'
import abimg3 from '../../images/wp/wp-3.png'
import { useLanguage } from '../LanguageContext';

const About = (props) => {

    const { state } = useLanguage();
	const { language } = state;
    return (
        <section id="about" className="wp-section pt-150 rmb-100" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="wp-images rmb-150">
                            <div className="experience-year">
                                {/* <span className="number">7</span> */}
                                <h4>{language === 'ar' ? 'خبرة أمريكية ، المانية ، سورية' : 'American, German, and Syrian experience'}</h4>
                            </div>
                            <img src={abimg1} alt="Wordpress" />
                            <img src={abimg2} alt="Wordpress" />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="wp-content ml-50 rml-0 rpt-30">
                            <div className="section-title mb-10">
                                <h6>                    <span>{language === 'ar' ? 'مع أفضل المهندسين في المجال ' : 'BEST ARCHITECT'}</span></h6>
                                <h2>{language === 'ar' ? 'نرسم مشروعك  ' : 'A small efficient'} <br />
                                    <span>{language === 'ar' ? 'ليلمس ارض الواقع ' : 'interior'}</span> <br />
                                    <span className="thin">{language === 'ar' ? 'ويبهر عملائك' : 'designing team'}</span>
                                </h2>
                                <h6>{language === 'ar' ? 'مرحبا بكم في ميرا جيرمني حيث يتميز الابداع' : 'welcome to Mira Building showcase'}</h6>
                            </div>
                            <img src={abimg3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;