import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

const VideoModal = () => {

  const [isOpen, setOpen] = useState(false)

  return (
    <React.Fragment>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="IdejM6wCkxA" onClose={() => setOpen(false)} />

      <button className="video-play" onClick={() => setOpen(true)}><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#2A2E85" class="bi bi-play-fill" viewBox="0 0 16 16">
  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
</svg></button>
    </React.Fragment>
  )
}

export default VideoModal;