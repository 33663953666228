import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from './LanguageContext';

const LanguageSelector = () => {
	const { state, dispatch } = useLanguage();

	const changeLanguage = (newLanguage) => {
		dispatch({ type: 'SET_LANGUAGE', payload: newLanguage });
		// window.open('/ar');
	};

	return (
		<div className='flex items-center space-x-4 text-black'>
			<Link href='/'>
				<span
					className={`${
						state?.language === 'en' ? 'languageselected' : ''
					} text-black px-3 py-1 rounded-full`}
					onClick={() => changeLanguage('en')}
				>
					English
				</span>
			</Link>
			<span>/</span>
			<Link href='/ar'>
				<span
					className={`${
						state?.language === 'ar' ? 'languageselected' : ''
					} text-black px-3 py-1 rounded-full`}
					onClick={() => changeLanguage('ar')}
				>
					Arabic
				</span>
			</Link>
		</div>
	);
};

export default LanguageSelector;
