import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';
const About3 = () => {

	const { state } = useLanguage();
	const { language } = state;

  return (
    <div className="container py-5">
      <h1 className="text-center mb-5">
        {language === 'ar' ? 'شركة ميرا جيرمني ' : 'About Mira Building'}
      </h1>
      <p>
        {language === 'ar' ? 
        'ميرا جيرمني هي  شركة مقاولات مقرها في رأس الخيمة، الإمارات العربية المتحدة. تميزت بالإلتزام بالتميز، ونمت بسرعة لتصبح لاعباً بارزاً في صناعة البناء والمقاولات داخل الإمارات العربية المتحدة' : 
        'Mira Building is a contracting company based in Ras Al Khaimah, United Arab Emirates. Established with a commitment to excellence, Mira Building has rapidly grown to become a prominent player in the construction and contracting industry within the United Arab Emirates.'}
      </p>
      <div className="my-4">
        <h2>{language === 'ar' ? 'نظرة عامة على الشركة' : 'Company Overview'}</h2>
        <p>
          {language === 'ar' ? 
          'تخصصت ميرا جيرمني في مجموعة واسعة من خدمات البناء والمقاولات، لتلبية الاحتياجات المتنوعة لعملائها. بتركيز على الجودة والابتكار والاستدامة، اكتسبت الشركة سمعة لتسليم المشاريع التي تتجاوز التوقعات' : 
          'Mira Building specializes in a wide range of construction and contracting services, catering to the diverse needs of its clients. With a focus on quality, innovation, and sustainability, the company has earned a reputation for delivering projects that exceed expectations.'}
        </p>
      </div>
      <div className="my-4">
        <h2>{language === 'ar' ? 'الخدمات الأساسية' : 'Core Services'}</h2>
        <ul className="list-unstyled">
          <li>
            <strong>{language === 'ar' ? 'خدمات البناء:' : 'Construction Services:'}</strong> 
            {language === 'ar' ? 
            'تقدم ميرا جيرمني حلول بناء و صيانه مميزة و استثنائية تشتهر الشركة بدقتها العالية في التفاصيل والتزامها بأعلى معايير البناء' : 
            'Mira Building offers comprehensive construction solutions, ranging from residential and commercial buildings to infrastructure projects. The company is known for its meticulous attention to detail and adherence to the highest construction standards.'}
          </li>
          <li>
            <strong>{language === 'ar' ? 'خدمات المقاولات:' : 'Contracting Services:'}</strong> 
            {language === 'ar' ? 
            'كشركة مقاولات، تدير ميرا جيرمني وتنفذ المشاريع بكفاءة، مع ضمان الانتهاء في الوقت المحدد والفعالية من حيث التكلفة. فريق من المهنيين المهرة في الشركة يشرف على كل جانب من جوانب عملية المقاولات، من التخطيط إلى التنفيذ' : 
            'As a contracting company, Mira Building manages and executes projects efficiently, ensuring timely completion and cost-effectiveness. The company’s team of skilled professionals oversees every aspect of the contracting process, from planning to execution.'}
          </li>
          <li>
            <strong>{language === 'ar' ? 'إدارة المشاريع:' : 'Project Management:'}</strong> 
            {language === 'ar' ? 
            'ملتزمون بتحقيق أعلى معايير الجودة والاحترافية في كل مشروع نقوم بتنفيذه. نفخر بخبرتنا الواسعة في مجال البناء والتشييد، حيث نقدم خدمات متكاملة تشمل التصميم، والتخطيط، وتنفيذ المشاريع بأسلوب فريد ومبتكر' : 
            'Mira Building excels in project management, utilizing industry best practices to ensure the successful delivery of projects on time and within budget. Their approach involves effective coordination, communication, and risk management.'}
          </li>
          <li>
            <strong>{language === 'ar' ? 'التجديد والصيانة:' : 'Renovation and Maintenance:'}</strong> 
            {language === 'ar' ? 
            'بالإضافة إلى مشاريع البناء الجديدة، تقدم ميرا جيرمني خدمات التجديد والصيانة. سواء كان الأمر يتعلق بتجديد الهياكل القائمة أو إجراء الصيانة الروتينية، تلتزم الشركة بالحفاظ على طول العمر ووظائف المباني' : 
            'In addition to new construction projects, Mira Building offers renovation and maintenance services. Whether it’s refurbishing existing structures or conducting routine upkeep, the company is committed to preserving the longevity and functionality of buildings.'}
          </li>
        </ul>
      </div>
      <div className="my-4">
        <h2>{language === 'ar' ? 'الإلتزام بالجودة' : 'Commitment to Quality'}</h2>
        <p>
          {language === 'ar' ? 
          'تعطي ميرا جيرمني الأولوية للجودة في كل مرحلة من مراحل عملية البناء. من اختيار أفضل المواد إلى توظيف العمالة الماهرة، تكرس الشركة لتسليم المشاريع التي تصمد أمام اختبار الزمن. توجد تدابير صارمة لمراقبة الجودة لضمان متانة وأمان الهياكل المكتملة.' : 
          'Mira Building prioritizes quality at every stage of the construction process. From selecting the finest materials to employing skilled labor, the company is dedicated to delivering projects that stand the test of time. Stringent quality control measures are in place to guarantee the durability and safety of completed structures.'}
        </p>
      </div>
      <div className="my-4">
        <h2>{language === 'ar' ? 'مبادرات الاستدامة' : 'Sustainability Initiatives'}</h2>
        <p>
          {language === 'ar' ? 
          'اعترافاً بأهمية ممارسات البناء المستدامة، تدمج مبنى ميرا حلول صديقة للبيئة في مشاريعها. تسعى الشركة جاهدة لتقليل تأثيرها البيئي من خلال اعتماد تقنيات فعّالة من حيث استهلاك الطاقة ومواد البناء الخضراء كلما أمكن ذلك' : 
          'Recognizing the importance of sustainable construction practices, Mira Building integrates eco-friendly solutions into its projects. The company strives to minimize its environmental impact by adopting energy-efficient technologies and green building materials whenever possible.'}
        </p>
      </div>
      <div className="my-4">
        <h2>{language === 'ar' ? 'الحضور المحلي' : 'Local Presence'}</h2>
        <p>
          {language === 'ar' ? 
          'مع مقرها في رأس الخيمة ووجود ملموس في أبو ظبي، تتمتع ميرا جيرمني بموقع ممتاز لخدمة العملاء في جميع أنحاء الإمارات. تسمح معرفتها المحلية، مقترنة بمنظور عالمي، لها بالتنقل في التحديات الفريدة لصناعة البناء في المنطقة' : 
          'Headquartered in Ras Al Khaimah, with a significant presence in Abu Dhabi, Mira Building is well-positioned to serve clients across the Emirates. The company’s local knowledge, combined with a global perspective, allows it to navigate the unique challenges of the construction industry in the region.'}
        </p>
      </div>
      <div className="my-4">
        <h2>{language === 'ar' ? 'رضا العملاء' : 'Client Satisfaction'}</h2>
        <p>
          {language === 'ar' ? 
          'تضع ميرا جيرمني تأكيداً قوياً على رضا العملاء. من خلال تعزيز التواصل والتعاون المفتوح، تبني الشركة علاقات طويلة الأمد مع العملاء والمعماريين . لقد ساهم هذا النهج المركزي على العملاء في نجاحها ونموها في سوق المقاولات التنافسي' : 
          'Mira Building places a strong emphasis on client satisfaction. By fostering open communication and collaboration, the company builds lasting relationships with clients, architects, and stakeholders. This customer-centric approach has contributed to its success and growth in the competitive construction market.'}
        </p>
      </div>
    </div>
  );
};

export default About3;
