import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import WhatsAppButton from '../../components/support/support';
import Hero from '../../components/hero/hero';
import About from '../../components/about/about';
// import FunFact from '../../components/FunFact';
// import About from '../../components/about/about';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import VideoSection from '../../components/VideoSection/VideoSection';
import PartnerSection from '../../components/PartnerSection';
import ResturantSec from '../../components/ResturantSec/ResturantSec';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import FunFact from '../../components/FunFact';
import FaqSection from '../../components/FaqSection/FaqSection';
import ContactSection from '../../components/ContactSection/ContactSection';
import Footer from '../../components/footer/Footer';

const HomePage = () => {
	return (
		<Fragment>
			<Navbar hclass={'header-style-3'} topbarNone={'topbar-none'} />
			<Hero />
			<About />
			<ServiceSection ptClass={'pt-250'} />
			<VideoSection />
			<PartnerSection />
			<ResturantSec />
			<ProjectSection />
			<FunFact />
			<FaqSection />
			<ContactSection />
			<Footer />
			<WhatsAppButton />
		</Fragment>
	);
};
export default HomePage;
