import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useLanguage } from '../LanguageContext';

const ContactForm = () => { // Assuming `language` is passed as a prop or obtained from context

    const [forms, setForms] = useState({
        name: '',
        email: '',
        date: '',
        time: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setForms({
                name: '',
                email: '',
                date: '',
                time: '',
                message: ''
            });
        } else {
            validator.showMessages();
        }
    };

	const { state } = useLanguage();
	const { language } = state;

    return (
        <section className="contact-page mb-30">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-11">
                        <form className="contact-form bg-light rmt-0" onSubmit={(e) => submitHandler(e)}>
                            <div className="section-title text-center mb-40">
                                <h2>{language === 'ar' ? 'طلب زيارة' : 'Request a Visit'}</h2>
                            </div>
                            <div className="row clearfix">
                                {/* Name Field */}
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            className='form-control'
                                            value={forms.name}
                                            type="text"
                                            name="name"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            placeholder={language === 'ar' ? 'الاسم' : 'Name'} />
                                        {validator.message('name', forms.name, 'required|alpha_space')}
                                    </div>
                                </div>
                                {/* Email Field */}
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            className='form-control'
                                            value={forms.email}
                                            type="email"
                                            name="email"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            placeholder={language === 'ar' ? 'البريد الإلكتروني' : 'Email'} />
                                        {validator.message('email', forms.email, 'required|email')}
                                    </div>
                                </div>
                                {/* Date Field */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            className='form-control'
                                            value={forms.date}
                                            type="text"
                                            name="date"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            placeholder={language === 'ar' ? 'التاريخ' : 'Date'} />
                                        {validator.message('date', forms.date, 'required|alpha_space')}
                                    </div>
                                </div>
                                {/* Time Field */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            className='form-control'
                                            value={forms.time}
                                            type="text"
                                            name="time"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            placeholder={language === 'ar' ? 'الوقت' : 'Time'} />
                                        {validator.message('time', forms.time, 'required|alpha_space')}
                                    </div>
                                </div>
                                {/* Message Field */}
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea
                                            className='form-control'
                                            rows="4"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            value={forms.message}
                                            type="text"
                                            name="message"
                                            placeholder={language === 'ar' ? 'الرسالة' : 'Message'}>
                                        </textarea>
                                        {validator.message('message', forms.message, 'required')}
                                    </div>
                                </div>
                                {/* Submit Button */}
                                <div className="col-md-12">
                                    <button type="submit" className="theme-btn mt-40">{language === 'ar' ? 'إرسال' : 'Send'}</button>
                                </div>
                            </div>
                            <div className="title-rotated">{language === 'ar' ? 'اتصال' : 'contact'}</div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm;  
