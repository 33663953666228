import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import "./WhatsAppButton.css"; // Import the CSS file for custom styles



const WhatsAppButton = () => {
  const phoneNumber = "+971554301077";
  const message = encodeURIComponent("Hello! Mira Building, I need your service.");

  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };

  return (
    <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 999 }}>
      <button onClick={handleClick} className="btn btn-success whatsapp-btn">
        <img
          
          src="whatsapp.png"
          alt="WhatsApp"
          className="whatsapp-logo"
        />
        Chat with us
      </button>
    </div>
  );
};

export default WhatsAppButton;
