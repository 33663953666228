import React from 'react'
import CountUp from 'react-countup';
import { useLanguage } from '../LanguageContext';

const FunFact = (props) => {
    const { state } = useLanguage();
    const { language } = state;

    const funFact = [
        {
            title: '100',
            subTitle: language === 'ar' ? 'مشاريع ' : 'Future ',
            sts: language === 'ar' ? 'مستقبلية' : 'Projects',
        },
        {
            title: '1000',
            subTitle: language === 'ar' ? 'عملاء' : 'Happy',
            sts: language === 'ar' ? 'سعداء' : 'Clients',
        },
        {
            title: '30',
            subTitle: language === 'ar' ? 'المشاريع ' : 'Completed',
            sts: language === 'ar' ? 'المنجزة' : 'Projects',
        },
        {
            title: '100',
            subTitle: language === 'ar' ? 'أعضاء ' : 'Team',
            sts: language === 'ar' ? 'فريقنا' : 'Members',
        },
    ];

    return (
        <section className={`success-section mb-115 ${props.fClass}`}>
            <div className="container">
                <div className="row">
                    {funFact.map((funfact, fitem) => (
                        <div className="col-xl-3 col-md-6" key={fitem}>
                            <div className="success-box">
                                <span className="count-text" data-stop={funfact.title}><CountUp end={funfact.title} enableScrollSpy /></span>
                                <div className="success-content">
                                    <h3>{funfact.subTitle}</h3>
                                    <h6>{funfact.sts}</h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>
    )
}

export default FunFact;
